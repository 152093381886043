<template>
    <div class="invoicing-process">
        <div class="facilityBox">
            <div class="settleBox">
                <el-row>
                    <el-col :span="5"><strong>发票抬头：</strong>{{ settlementInfo.TitleName }}</el-col>
                    <el-col :span="5"><strong>纳税人识别号：</strong>{{ settlementInfo.TitleCreditCode }}</el-col>
                    <el-col :span="5"><strong>开户银行：</strong>{{ settlementInfo.TitleBankerName }}</el-col>
                    <el-col :span="9"><strong>开户银行账号：</strong>{{ settlementInfo.TitleBankerNumber }}</el-col>
                </el-row>
                <el-row style="margin:14px 0px;">
                    <el-col :span="5"><strong>运单条数：</strong>{{ settlementInfo.BillCount }}条</el-col>
                    <el-col :span="5"><strong>运费(元)：</strong> <span style="color: #FF8B17;font-weight: bold;">
                    <!-- {{settlementInfo.BaseAmount | formatMoney }} -->
                    /
                        </span></el-col>
                    <el-col :span="5"><strong>服务费/税费(元)：</strong><span style="color: #FF8B17;font-weight: bold;">{{
                        settlementInfo.SupplierProfit |
                        formatMoney }}</span></el-col>
                    <el-col :span="9"><strong>运单总金额(元)：</strong><span style="color: #FF8B17;font-weight: bold;">{{
                        settlementInfo.TotalAmount |
                        formatMoney }}</span>
                    </el-col>
                </el-row>
                <el-row style="margin:14px 0px;">
                    <el-col :span="5"><strong>费率：</strong>{{ settlementInfo.SuplierRateStr }}</el-col>
                    <el-col :span="5"><strong>支付方式：</strong>{{ settlementInfo.PayModeName }}</el-col>
                    <el-col :span="5"><strong>联系电话：</strong>{{ settlementInfo.TitlePhone }}</el-col>
                    <el-col :span="9"><strong>单位地址：</strong>{{ settlementInfo.TitleAdress }}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="5">
                        <strong>发票类型：</strong>
                        <span v-if="settlementInfo.InvoicingType == 1">{{ settlementInfo.InvoiceKindName }}</span>
                        <span v-else>{{ settlementInfo.InvoiceKindName }}-{{ settlementInfo.InvoiceKindTecName }}</span>
                    </el-col>
                    <el-col :span="5"><strong>业务类型：</strong>{{ settlementInfo.BusinessTypeName }}</el-col>
                    <el-col :span="5"><strong>场景类型：</strong>{{ settlementInfo.ScenarioTypeName }}</el-col>
                    <el-col :span="9"><strong>开票类型：</strong>{{ settlementInfo.InvoiceTypeName }}</el-col>
                </el-row>
            </div>
            <!-- 未组合发票 -->
            <div v-if="status == 10">
                <div style="position: absolute;right:20px;top:108px;z-index: 999">
                    <el-tooltip placement="top" effect="light">
                        <div slot="content">
                            <span style="font-weight: bold;">自动组合：</span>
                            <span>你可以根据情况使用自动组合，选择自动组合的条件后将会自动统计出需要开具的发票数量</span>
                        </div>
                        <div style="margin: 0px 20px;color: #0F5FFF;">
                            <i class="el-icon-question"></i>
                            <span>帮助</span>
                        </div>
                    </el-tooltip>
                </div>
                <!-- 选择发票面额 -->
                <div style="margin-bottom: 8px;">
                    <span>发票面额：</span>
                    <el-select class="select" v-model="facaValue" placeholder="请选择发票面额">
                        <el-option v-for="item in facaValueList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <el-table :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
                    v-loading="loading" key="tableData">
                    <el-table-column type="index" label="序号" width="50" align="center" fixed="left" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="BBID" label="运单号" align="center" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="ApplicantName" label="货主单位" align="center" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="SupplierProfit" label=" 服务费/税费" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span style="color:#FF8B17">{{ scope.row.SupplierProfit | formatMoney }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="TotalAmount" label="运单总金额" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span style="color:#FF8B17">{{ scope.row.TotalAmount | formatMoney }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ApplyTime" label="付款时间" align="center" show-overflow-tooltip>
                    </el-table-column>
                </el-table>
                <div style="margin: 10px 0 0 0">
                    <!-- 分页 -->
                    <el-pagination class="pagination" background @current-change="handleCurrentChange"
                        :current-page.sync="pagination.page" :page-size="pagination.pagesize"
                        layout="total, prev, pager, next, jumper" :total="pagination.total">
                    </el-pagination>
                </div>
                <div style="display:flex;align-items:center;justify-content: center;">
                    <el-button type="info" plain size="medium" icon="el-icon-back" @click="goBack">返回
                    </el-button>
                    <el-button type="primary" size="medium" icon="el-icon-connection" @click="chooseInvoiceDialig = true">
                        自动组合
                    </el-button>
                </div>
                <!-- 点击自动组合弹窗发票选择框 -->
                <el-dialog :visible.sync="chooseInvoiceDialig" title="发票选择" width="570px">
                    <div style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 16px;">
                        <el-radio v-model="combinType" label="Order">组合成一个发票包进行开票</el-radio>
                    </div>
                    <div style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 16px;">
                        <el-radio v-model="combinType" label="OrderOne">每条运单单独进行开票</el-radio>
                        <div>请确保发票充足，以免开票被驳回</div>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="chooseInvoiceDialig = false">取 消</el-button>
                        <el-button type="primary" @click="autoCombinedinvoice">确 定</el-button>
                    </span>
                </el-dialog>
            </div>
            <!-- 已组合发票 -->
            <AlreadyCombinedInvoice v-if="status >= 19" :bsid="bsid" @changeStatus="changeStatus" :taskTypeID="taskTypeID">
            </AlreadyCombinedInvoice>
        </div>
    </div>
</template>
<script>
import { getDenomination } from '@/api/supplierWaybill/basicWaybill/makeInvoice'
import { GetStatementDetailList, GetStatementInfo } from "@/api/purchaseManage/invoiceFlow";
import { AutoCombinedinvoice } from "@/api/purchaseManage/markOut";
import AlreadyCombinedInvoice from './cmpt/alreadyCombinedInvoice'
export default {
    data() {
        return {
            bsid: '', // 运单开票编号
            facaValue: '', // 发票面额 
            facaValueList: [{ label: 0, value: 0 }], //发票面额列表
            tableData: [],
            loading: false,
            chooseInvoiceDialig: false, // 发票选择弹窗
            pagination: {
                page: 1, //当前所处的页码
                pagesize: 10, //每次请求的数量
                total: 0, //总条数
            },
            combinType: 'Order', // 发票选择
            status: '', // 10 服务商同意开票，还未组合发票 19 已暂存组合发票
            settlementInfo: {},
            taskTypeID: '', // 场景类型
        }
    },
    methods: {
        // 获取列表
        getStatementDetailList() {
            this.loading = true
            GetStatementDetailList({
                pageSize: this.pagination.pagesize,
                pageIndex: this.pagination.page,
                bsid: this.bsid
            }).then(res => {
                this.tableData = res.data.DataList;
                this.pagination.total = Number(res.data.TotalCount);
            }).finally(() => {
                this.loading = false
            })
        },
        //分页控件页码change事件回调
        handleCurrentChange(e) {
            this.pagination.page = e;
            // 获取列表
            this.getStatementDetailList();
        },
        // 返回
        goBack() {
            this.getStatementDetailList();
            if (this.$route.query.activeCmpt) {
                this.$router.push(`/commission/index/purchaseInvoicing?activeCmpt=${this.$route.query.activeCmpt}`);
            } else {
                this.$router.push(`/commission/index/purchaseInvoicing`)
            }
        },
        // 自动组合发票
        autoCombinedinvoice() {
            if (!this.facaValue) {
                this.$message.error('请选择发票面额')
                return
            }
            const loading = this.$loading({
                lock: true,
                text: '组合发票中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            AutoCombinedinvoice({
                bsid: this.bsid,
                denomination: this.facaValue,
                ctype: this.combinType
            }).then(res => {
                this.chooseInvoiceDialig = false
                this.combinType = 'Order'
                this.$message.success("发票组合成功")
                this.status = 19
                this.getStatementDetailList();
            }).finally(() => {
                loading.close()
            })
        },
        // 获取发票面额
        getDenominationList() {
            //  获取发票面额
            getDenomination().then(res => {
                this.facaValue = res.denomin.Denomination
                this.facaValueList = [{ label: this.facaValue, value: this.facaValue }]
            })
        },

        // 修改status值
        changeStatus(val) {
            this.status = val
            this.getDenominationList()
            this.getStatementDetailList();
        },
        // 获取六要素等开票信息
        async getInvoiceTitleInfo() {
            await GetStatementInfo({
                BSID: this.bsid
            }).then(res => {
                this.settlementInfo = res.data || {}
            })
        },
    },
    created() {
        this.bsid = this.$route.query.bsid ? this.$route.query.bsid : ''
        this.status = this.$route.query.status ? this.$route.query.status : ''
        this.taskTypeID = this.$route.query.taskTypeID ? this.$route.query.taskTypeID : ''
        this.getInvoiceTitleInfo()
        if (this.status == 10) {
            this.getStatementDetailList()
            this.getDenominationList()
        }
    },
    components: {
        AlreadyCombinedInvoice
    }
}
</script>
<style lang="scss">
.invoicing-process {
    .settleBox {
        margin: 10px 0px;
        padding: 10px 16px;
        background-color: #f0f0f0;
        color: #666;
    }
}
</style>
